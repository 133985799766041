import React from 'react';

const Locked = () => {
  return (
    <div>
      <h2 style={{ margin: '30px', color: 'red'}}>Internal Server Error. Try Again..</h2>
    </div>
  );
};

export default Locked;